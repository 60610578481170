import { lazy, Suspense } from "react";

import { useSelector } from "react-redux";

import { selectActiveRole } from "./features/auth/authSlice";

// Components
///import Index from "./jsx";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Loader from "./jsx/components/Loader";
import AuthGuard from "./features/auth/AuthGuard";
import PersistLogin from "./features/auth/PersistLogin";
// action


/// Style
import "./styles/global.scss";
import { mainMenu } from "./config/routes";
import Dashboard from "./pages/dashboard/Dashboard";
import Home from "./pages/Home";
import NoPage from "./jsx/pages/NoPage";

import Page from "./jsx/Page";

const Login = lazy(() => import("./pages/auth/Login"));


const generateRoutes = (activeRole, itemArray) => {
  if (!activeRole || !itemArray) return null;
  
  return itemArray.flatMap((item) => {
    if (item.roles.includes(activeRole) || item.roles.length == 0) {
      if (item.component) {
        return (
          <Route
            key={item.label}
            path={item.path}
            element={<Page menuArray={itemArray} title={item.label} component={item.component} />}
          />
        );
      }
      if (item.subItems) {
        return item.subItems.map((subItem) => (
          <Route
            key={`${item.path}${subItem.path}`}
            path={`${item.path}${subItem.path}`}
            element={<Page menuArray={itemArray} title={subItem.label} component={subItem.component} />}
          />
        ));
      }
    }
    return null;
  });
};

const router = (activeRole) => createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/login" element={<Login />} />
      <Route element={<PersistLogin />}>
        <Route element={<AuthGuard />}>
          <Route index element={<Home />} />
          <Route
                path={'/dashboard'}
                element={<Page menuArray={mainMenu} title={"Dashboard"} component={< Dashboard />} />}
          />
          {generateRoutes(activeRole, mainMenu)}
          

          <Route path="*" element={<NoPage />} />
        </Route>
      </Route>
    </Route>
  )
);

const App = () => {
  const activeRole = useSelector(selectActiveRole);
  console.log(`Actve role = ${activeRole}`)
  return (
    <Suspense fallback={<Loader />}>
       <RouterProvider router={router(activeRole)} />
    </Suspense>
  );
};

export default App;
