export default function ProfilePicture({ thumbnail }) {
  if (thumbnail === true) {
    return (
      <>
        <img
          src="/icons/profile.svg"
          style={{ height: "32px", width: "auto" }}
          alt=""
        />
      </>
    );
  } else {
    return (
      <>
        <div className="d-inline-block text-center h-100 ">
          <img
            src="/icons/profile.svg"
            style={{ height: "100%", width: "auto" }}
            alt=""
          />
        </div>
      </>
    );
  }
}
