import { useNavigate } from "react-router-dom";
import { paths } from "../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentLoginStatus, setLogout } from "../features/auth/authSlice";
import { useLogoutMutation } from "../features/auth/authApiSlice";
import { useState, useRef } from "react";
import ProfilePicture from "../jsx/components/ProfilePicture";
import { Dropdown } from "react-bootstrap";
import {
  selectActiveRole,
} from "../features/auth/authSlice";

import { List } from "react-bootstrap-icons";

export default function TopNav({ toggled, setToggled }) {
  const loginStatus = useSelector(getCurrentLoginStatus);
  const activeRole = useSelector(selectActiveRole);
  const [logout] = useLogoutMutation();
  
  const [errMsg, setErrMsg] = useState("");
  const nav = useNavigate();

  const data = { name: "Logged", surname: "User" };

  const dispatch = useDispatch();


  const roleText = "Superuser";
  const toggleRef = useRef(null);

  const handleToggleClick = () => {
    if (toggleRef.current) {
      toggleRef.current.click(); // Trigger the click event on the Dropdown.Toggle
    }
  };



  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      //dispatch a function which performs the logout
      await logout().unwrap();
      dispatch(setLogout());
      dispatch({ type: "RESET" }); //calls the root reducer with RESET as action!
      nav("/login");
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.originalStatus === 400) {
        setErrMsg("General error");
      } else if (err.originalStatus === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Logout Failed");
      }
    }
  };

  return (
    <>
      <div className="container-fluid py-2 bg-white">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto">
            <span>
              {" "}
              {/*hamburger menu*/}
              <List
                className="MenuIcon"
                role="button"
                size={32}
                onClick={() => setToggled(!toggled)}
              />
            </span>
          </div>
          {loginStatus && (
            <div className="col-auto">
              <div className="row align-items-center ">
                <div className="col-auto p-0 ">
                  <div role="button" onClick={handleToggleClick}>
                    <ProfilePicture thumbnail={true} />
                  </div>
                </div>

                <div className="col-auto p-0">
                  <Dropdown>
                    <Dropdown.Toggle
                      ref={toggleRef}
                      style={{
                        background: "transparent",
                        border: "none",
                      }}
                    ></Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          nav(paths.settings + "/profile");
                        }}
                      >
                        Account
                      </Dropdown.Item>
                      

                      <Dropdown.Item onClick={handleLogout}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-auto p-0">
                  {data && (
                    <>
                      <div
                        className="row"
                        style={{ marginRight: "24px" }}
                        role="button"
                        onClick={handleToggleClick}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {data.name} {data.surname}
                        </span>
                      </div>
                      <div className="row">
                        <small>{roleText}</small>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
