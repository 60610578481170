import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCreateProjectMutation } from "../../features/projects/projectApiSlice";
import {
  selectNumberParticipants,
  selectAllWizard,
  clearWizard,
} from "../../features/wizard/wizardSlice";
import { PrevNext } from "./components/PrevNext";
import FormLabel from "./components/FormLabel";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { wizard } from "../../config/routes";

export const Summary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const wizardData = useSelector(selectAllWizard);
  const count = useSelector(selectNumberParticipants);

  useEffect(()=>{
    if (count === 0) {
      navigate(wizard[1].path);
    }

  },[])

  const MySwal = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary m-2",
        cancelButton: "btn btn-secondary m-2",
      },
      buttonsStyling: false,
    })
  );

  if (!wizardData.modules.length) {
    navigate(wizard[0].path);
  }

  const presentationCheck = (value) => (value === true ? "Yes" : "No");

  const [CreateProject] = useCreateProjectMutation();

  const fireModal = (input, error = false) => {
    if (!error) {
      MySwal.fire({
        title: <h3>{input.message.title}</h3>,
        html: <i>{input.message.body}</i>,
        showCancelButton: true,
        cancelButtonText: "Home",
        confirmButtonText: "project settings",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(clearWizard());
          navigate("/survey-invites");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          dispatch(clearWizard());
          navigate("/");
        } else {
          dispatch(clearWizard());
        }
      });
    } else
      MySwal.fire({
        title: <h3>{input.data.message}</h3>,
        html: <i>{input.data.data}</i>,
        confirmButtonText: "close",
        icon: "error",
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!window.confirm("This will create a new project, are you sure?"))
      return;
    setSending(true);
    async function asyncCall() {
      await CreateProject(wizardData)
        .unwrap()
        .then((payload) => {
          setSending(false);
          fireModal(payload);
        })
        .catch((error) => {
          setSending(false);
          fireModal(error, (error = true));
        });
    }
    asyncCall();
  };

  return (
    <>
      <PrevNext
        prevClicked={() => {
          navigate(wizard[2].path);
        }}
        completeClicked={handleSubmit}
        sending={sending}
        step={"4 of 4"}
      />
      <div className="container-fluid">
        <div className="row align-items-center ">
          <div className="col-lg-4 color-blue-dark m-auto mt-5 mt-lg-0">
            <div className="row text-center color-blue-dark m-auto mt-5 mt-lg-0">
              <h3>
                <b> Summary </b>
              </h3>
            </div>
            <div className="pt-1">
              <FormLabel label={"Project Title"} />
              <br></br>
              {wizardData.title}
            </div>
            <div className="pt-1">
              <FormLabel label={"Project Description"} />
              <br></br>
              {wizardData.description}
            </div>
            <div className="pt-1">
              <FormLabel label={"Modules selected"} />
              <ul>
                {wizardData.modules &&
                  wizardData.modules.map((module, i) => {
                    if (module.selected === true) {
                      return <li key={'mod'+ i}>{module.name}</li>;
                    }
                  })}
              </ul>
            </div>
            <div className="pt-1">
              <FormLabel label={"Zoom presentation requested ?"} />
              <br></br>
              {presentationCheck(wizardData.presentation)}
            </div>
            <div className="pt-1">
              <FormLabel label={"Number of participants"} />
              <br></br>
              {count}
            </div>
          </div>
          <div className="pt-1"></div>
        </div>
      </div>
    </>
  );
};
