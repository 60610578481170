import { apiSlice } from "../../api/apiSlice"
import { setProjects } from "./projectSlice"

export const projectApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProjects: builder.query({
            query: (uid) => `/users/${uid}/projects/`,
            keepUnusedDataFor: 300,
            providesTags: ['Projects']
        }),
        getProjectSurveys: builder.query({
            query: (pid) => `projects/${pid}/surveys`,
            keepUnusedDataFor: 30,
            providesTags: ['ProjectsEmails']
        }),
        getProjectEmployees: builder.query({
            query: (pid) => `projects/${pid}/employees`,
            keepUnusedDataFor: 30,
            providesTags: ['ProjectsEmployees']
        }),
        sendInvites: builder.mutation({
            query:({pid, sid}) =>({
                url: `projects/${pid}/surveys/${sid}/invites`,
                method: 'POST',
                body: {sid},
            }),
            invalidatesTags: ['ProjectsEmails']
        }),
        createProject: builder.mutation({
            query:(projectData) =>({
                url: `projects/`,
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                  },
                body: JSON.stringify(projectData),
            }),         
            invalidatesTags: ['Projects']
        }),
        updateUserAccess: builder.mutation({
            query:({pid, body }) =>({
                url: `projects/${pid}/users/`,
                method: 'PUT',
                body
            }),         
            invalidatesTags: ['ProjectsEmployees']
        }),
        
    })
})

export const {
    useGetProjectsQuery,
    useGetProjectSurveysQuery,
    useGetProjectEmployeesQuery,
    useSendInvitesMutation,
    useUpdateUserAccessMutation,
    useCreateProjectMutation
} = projectApiSlice 
