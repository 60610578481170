import React from "react";
import { Button } from "react-bootstrap";
import AddNewDialog from "./AddNewDialog";
import swal from "sweetalert";
import {
  SelectEditMode,
  setEditMode,
  SelectLocaleID,
  SelectSurveyID,
  setLocaleID,
  SelectSurveyLocales,
} from "../../../../features/translations/translationsSlice";
import { useDeleteTranslationMutation } from "../../../../features/translations/translationsApiSlices";
import { useSelector, useDispatch } from "react-redux";
import { useGetLocalesQuery } from "../../../../features/config/configApiSlice";
import Loader from "../../../components/Loader";
import useFireModal from "../../../../hooks/useFireModal";

const ActionCard = () => {
  const {
    data: systemLocales,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetLocalesQuery();
  const editMode = useSelector(SelectEditMode);
  const localeID = useSelector(SelectLocaleID);
  const surveyID = useSelector(SelectSurveyID);
  const surveyLocales = useSelector(SelectSurveyLocales);
  const [deleteTranslation] = useDeleteTranslationMutation();
  const dispatch = useDispatch();

  const [fireModal] = useFireModal();

  const handleDeleteAction = async (value) => {
    //add the delete function here
    if (!localeID) {
      swal("Oops", "please select a language to Delete", "error");
      return;
    }

    // send editedData to API awaiting the response
    if (
      !window.confirm(
        `This will Delete the current selected translation, are you sure?`
      )
    )
      return;
    const data = [];

    await deleteTranslation({
      data,
      surveyID,
      localeID,
    })
      .unwrap()
      .then((payload) => {
        fireModal(payload);
        dispatch(setEditMode(false));
        dispatch(setLocaleID(-1));
      })
      .catch((error) => {
        fireModal({}, (error = true));
      });

    //depends of request save or save and close so update the redux store
  };

  const handleLocaleChange = (event) => {
    event.preventDefault();
    dispatch(setLocaleID(parseInt(event.target.value)));
  };

  const handleEditAction = (value) => {
    if (!localeID) {
      swal("Oops", "please select a language to edit", "error");
      return;
    }
    //load the data into redux
    dispatch(setEditMode(true));
  };

  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      {!editMode && surveyID && (
        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Translation actions</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="form-group mb-3">
                  <div className="input-group mb-3">
                    <select
                      value={localeID}
                      onChange={(event) => handleLocaleChange(event)}
                      className="form-control"
                      id="sel1"
                    >
                      <option value={-1}>{"--- choose a Lanuage ---"}</option>
                      {surveyLocales.map((option, index) => (
                        <option key={index} value={[option.id]}>
                          {option.id} {"("} {option.locale} {")"}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <Button
                    className="me-2"
                    variant="primary"
                    disabled={localeID === -1}
                    onClick={handleEditAction}
                  >
                    Edit
                  </Button>
                  <Button
                    className="me-2"
                    variant="danger"
                    disabled={localeID === -1}
                    onClick={handleDeleteAction}
                  >
                    Delete
                  </Button>
                  <AddNewDialog
                    systemLocales={systemLocales}
                    surveyLocales={surveyLocales}
                    setLocaleID={setLocaleID}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActionCard;
