import { ButtonWithIcon } from "./ButtonWithIcon";
import { buttonIcons } from "./buttonIcons";
import { Spinner } from "react-bootstrap";

export const PrevNext = ({
  prevClicked = null,
  prevDisabled = false,
  nextClicked = null,
  nextDisabled = false,
  completeClicked = null,
  completeDisabled = false,
  sending,
  step = "",
}) => {
  return (
    <>
      <div className="container py-4">
        <div className="row  pb-5 justify-content-md-center">
          <div className="col col-lg-2 text-center">
            {" "}
            {prevClicked && (
              <ButtonWithIcon
                onClick={prevClicked}
                disabled={prevDisabled}
                bgColor={"#FAAA8D"}
                text={"Previous"}
                iconName={buttonIcons.left}
              />
            )}
          </div>
          <div className="col-md-auto">
            <h2>
              <b>Create a new project {step}</b>
            </h2>
          </div>
          <div className="col col-lg-2 text-center">
            {nextClicked && (
              <ButtonWithIcon
                onClick={nextClicked}
                disabled={nextDisabled}
                bgColor={"#17B890"}
                text={"Next"}
                iconName={buttonIcons.right}
              />
            )}

            {completeClicked && (
              <form id="myform" onSubmit={completeClicked}>
                <div className="text-left form-group mb-3">
                  {(sending && (
                    <button
                      className="btn btn-primary btn-block"
                      disabled={completeDisabled}
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Creating project...
                    </button>
                  )) || (
                    <button
                      type="submit"
                      id="SendButton"
                      disabled={completeDisabled}
                      className="btn btn-primary btn-block"
                    >
                      Complete
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
          <div className="col-lg-5 text-start">
            <div
              className="color-blue-dark m-auto mt-5 mt-lg-0"
              style={{ maxWidth: 500 }}
            >
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
