import { Link } from "react-router-dom";

function MenuHeader() {
  return (
    <div className="container-fluid">
      <Link to="/">
      <img className="menu-header " src="/AdminMenuLogo.webp" height={200} alt="" />
      </Link>
    </div>
  );
}

export default MenuHeader;
