/// Dashboard
import Home from "../pages/dashboard/Dashboard";

// User Management
import Users from "../jsx/pages/users/Users";

// Translation Management
import Translations from "../jsx/pages/translations/Translations";

/// Auth Pages
import Login from "../pages/auth/Login";

// wizard modules
import { Modules } from "../pages/wizard/Modules";
import { Participants } from "../pages/wizard/Participants";
import { Presentation } from "../pages/wizard/Presentation";
import { Summary } from "../pages/wizard/Summary";

export const paths = {
  dashboard: "/dashboard",
};

export const mainMenu = [
  {
    label: "Dashboard",
    path: "/dashboard",
    roles: ["superuser"],
    link: "/dashboard",
    component: null, // this is the default home route and is defined in the app.js route for all roles
  },
  {
    label: "Roles and Permissions",
    path: "/roles-permissions",
    roles: ["superuser"],
    subItems: [
      {
        label: "Users",
        path: "/roles",
        roles: ["superuser"],
        component: <Users />,
      },
    ],
  },
  {
    label: "User Management",
    path: "/user-management",
    roles: ["superuser"],
    subItems: [
      {
        label: "Users",
        path: "/users",
        roles: ["superuser"],
        component: <Users />,
      },
    ],
  },
  {
    label: "Survey Management",
    path: "/survey-management",
    roles: ["superuser"],
    subItems: [
      {
        label: "Translations",
        path: "/survey-translations",
        roles: ["superuser"],
        component: <Translations />,
      },
    ],
  },
  {
    label: "Project Management",
    path: "/project-management",
    roles: ["superuser"],
    subItems: [
      {
        label: "Wizard Pt1.",
        path: "/project-wizard/1",
        roles: ["superuser"],
        component: <Modules />,
      },
      {
        label: "Wizard Pt2.",
        path: "/project-wizard/2",
        roles: ["superuser"],
        component: <Participants />,
      },
      {
        label: "Wizard Pt3.",
        path: "/project-wizard/3",
        roles: ["superuser"],
        component: <Presentation />,
      },
      {
        label: "Wizard Pt4.",
        path: "/project-wizard/4",
        roles: ["superuser"],
        component: <Summary />,
      },
    ],
  },
];


export const wizard = [
  {
    path: "/project_wizard" + "/modules",
    iconActive: "survey-icon-5-selected.svg",
    iconInActive: "survey-icon-5.svg",
  },
  {
    path: "/project_wizard" + "/participants",
    iconActive: "settings-3-selected.svg",
    iconInActive: "settings-3.svg",
  },
  {
    path: "/project_wizard" + "/presentation",
    iconActive: "survey-icon-2-selected.svg",
    iconInActive: "survey-icon-2.svg",
  },
  {
    path: "/project_wizard" + "/summary",
    iconActive: "settings-5-selected.svg",
    iconInActive: "settings-5.svg",
  },
];


export const routes = [
  /// Dashboard
  { url: "", component: <Home /> },
  { url: "dashboard", component: <Home /> },
  /// User Management
  { url: "users", component: <Users /> },

  // Translation Management
  { url: "translations", component: <Translations /> },

  /// auth pages

  { url: "page-login", component: <Login /> },
];

export default routes;
