import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import usePersist from "../../hooks/usePersist";
import { SERVER_LIST } from "../../config/constant";
import { saveServerInLocalStorage } from "../../config/functions";

function Login() {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [server, setServer] = useState(-1);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [persist, setPersist] = usePersist();
  const [login] = useLoginMutation();

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (server != -1) {
      saveServerInLocalStorage(server);
      try {
        const userData = await login({ user, pwd }).unwrap();
        dispatch(setCredentials({ ...userData, user }));
        setUser("");
        setPwd("");
        if (location.state?.from) {
          navigate(location.state.from);
        } else {
          navigate("/");
        }
      } catch (err) {
        if (!err?.status) {
          // isLoading: true until timeout occurs
          setErrMsg("No Server Response");
        } else if (err.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.status === 401) {
          setErrMsg("Login Failed");
        } else {
          setErrMsg("Login Failed");
        }
      }
    } else {
      setErrMsg("Please select a server");
    }
  };

  const handleToggle = () => setPersist((prev) => !prev);

  return (
    <div class="container-flex">
      <div class="row">
        <div class="form-side col-12 login">
          <div className="container login-content style-1 ">
            <div className="row no-gutters justify-content-center">
              <div className="col-12 ">
                <div id="sign-in" className="auth-form form-validation">
                  {errMsg && (
                    <div className="login-error border p-1 my-2">{errMsg}</div>
                  )}
                  <form onSubmit={handleSubmit} className="form-validate">
                    <h1 className="text-center mb-4 login-header">
                      Admin Portal
                    </h1>

                    <div className="form-group mb-3">
                      <label className="mb-1">
                        <strong>ServerName</strong>
                      </label>
                      <div>
                        <select
                          value={server}
                          className="select"
                          onChange={(e) => setServer(parseInt(e.target.value))}
                        >
                          <option value={-1}>---- Select a Option ----</option>
                          {SERVER_LIST.map((item, i) => {
                            return (
                              <option key={`${item.name}_${i}`} value={i}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="val-email">
                        <strong>Email</strong>
                      </label>
                      <div>
                        <input
                          type="email"
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                          placeholder="Type Your Email Address"
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-1">
                        <strong>Password</strong>
                      </label>
                      <div>
                        <input
                          type="password"
                          value={pwd}
                          placeholder="Type Your Password"
                          onChange={(e) => setPwd(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                      <div className="form-group mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="basic_checkbox_1"
                          onChange={handleToggle}
                          checked={persist}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="basic_checkbox_1"
                        >
                          Remember my preference
                        </label>
                      </div>
                    </div>
                    <div className="text-end form-group mb-3">
                      <button type="submit" className="btn btn-primary">
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
