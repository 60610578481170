import {
    menuClasses,
  } from "react-pro-sidebar";

export const menuColors = {
    sidebar: {
      backgroundColor: "#2185D1" ,
      color: "#black",
    },
    menu: {
      menuContent: "#2185D1",
      icon: "#black",
      
      hover: {
        backgroundColor: "#a5c8f2",
        color: "#222222",
      },
      disabled: {
        color: "#3e5e7e",
      },
      active: {
        backgroundColor: "#a5c8f2",
        color: "#222222",
      },
    },
    subMenu: {
      menuContent: "#082440",
      icon: "#black",
      hover: {
        backgroundColor: "#a5c8f2",
        color: "#222222",
      },
      disabled: {
        color: "#3e5e7e",
      },
      active: {
        backgroundColor: "#a5c8f2",
        color: "#222222",
      },
    },
    label: {
      menuContent: "#082440",
      icon: "#black",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
      active: {
        backgroundColor: "#a5c8f2",
        color: "#222222",
      },
    }
};


export const menuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 400,
    },
    icon: {
      color: menuColors.menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: menuColors.menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#black",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0 ? menuColors.menu.menuContent : "transparent",
    }),

    button: ({ level, active, disabled }) => {
      if (level === 0) {
        return {
          [`&.${menuClasses.disabled}`]: {
            color: menuColors.menu.disabled.color,
          },
          [`&.${menuClasses.active}`]: {
            backgroundColor: menuColors.menu.active.backgroundColor,
            color: menuColors.menu.active.color,
          },
          "&:hover": {
            backgroundColor: menuColors.menu.hover.backgroundColor,
            color: menuColors.menu.hover.color,
          },
        };
      }
      if (level === 1) {
        return {
          marginTop: "5px",
          marginBottom: "5px",
          [`&.${menuClasses.disabled}`]: {
            color: menuColors.subMenu.disabled.color,
          },
          [`&.${menuClasses.active}`]: {
            color: menuColors.subMenu.active.color,
          },
          "&:hover": {
            backgroundColor: menuColors.subMenu.hover.backgroundColor,
            color: menuColors.subMenu.hover.color,
          },
        };
      }
    },

    label: ({ level, active, open }) => {
      if (level === 0) {
        return {
          fontWeight: open ? 600 : undefined,
          whiteSpace: "normal", // Allow text to wrap
          overflow: "visible", // Ensure the container can grow
          wordWrap: "break-word", // Break long words if necessary
          fontSize: "16px",
        };
      }
      if (level === 1) {
        return {
          fontWeight: open ? 600 : undefined,
          whiteSpace: "normal", // Allow text to wrap
          overflow: "visible", // Ensure the container can grow
          wordWrap: "break-word", // Break long words if necessary
          fontSize: "16px",
          padding: "5px",
          paddingLeft: "15px",
          [`&.${menuClasses.active}`]: {
            backgroundColor: menuColors.label.active.backgroundColor,
            borderRadius: "50px",
            color: menuColors.label.active.color,
          },
        };
      }
    },
  };