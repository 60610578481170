import React, { useMemo, useState } from "react";

import { Table } from "react-bootstrap";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import { ColumnFilter } from "./ColumnFilter";
//import { Checkbox } from "./CheckBox";
import TableToolbar from "./TableToolbar";
import Editable from "./Editable";
import swal from "sweetalert";

export const EditableTable = ({
  columns,
  data,
  error,
  loading,
  setData,
  getData,
  sendDataAPI,
  skipPageReset,
}) => {
  // handles the update function of the table
  const formFields = {};
  columns.forEach((column) => {
    if (column.editable === true) {
      const key = column.accessor;
      formFields[key] = "000";
    }
  });
  const [editFormData, setEditFormData] = useState(formFields);
  // edit  data
  const [editRowId, setEditRowId] = useState(null);

  const addUserHandler = async (user) => {
    const newData = data.concat([user]);
    setData(newData);
  };

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  {
    /* TO BE REVIEWED for future implementation
  const handleEditClick = (event, row) => {
    setEditRowId(row.id);
    event.preventDefault();
    const formDefault = {};
    row.cells.forEach((cell) => {
      if (cell.column.editable === true) {
        formDefault[cell.column.id] = cell.value;
      }
    });
    setEditFormData(formDefault);
  };
  */
  }

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedData = data[editRowId];
    // loop through edit form data and update the values in the newData
    Object.keys(editFormData).forEach((key) => {
      editedData[key] = editFormData[key];
    });

    // send the new data to the API to be validated and await the return
    sendDataAPI(editedData, "PATCH", `/users/update/${editedData.id}`).then(
      function (result) {
        if (result.success === "True") {
          //update the userlist with the new data!
          const newData = [...data];
          newData[editRowId] = editedData;
          setData(newData);
          swal("Good job!", "Updated sucess", "success");
          setEditRowId(null);
        } else {
          swal("Oops", result.message, "error");
          setEditRowId(null);
        }
      },
      (err) => {
        console.log(err); // Error: "Promise rejected"
      }
    );
  };

  const handleDeleteClick = (row) => {
    sendDataAPI(
      data[row.id],
      "DELETE",
      `/users/delete/${data[row.id].id}`
    ).then(
      function (result) {
        // console.log(result); "Promise resolved successfully"
        if (result.success === "True") {
          // the below code is pautly
          const newData = [...data];
          newData.splice(row.id, 1);
          setData(newData);
        } else {
          swal("Oops", result.message, "error");
        }
      },
      (err) => {
        swal("Oops", err, "error"); // Error: "Promise rejected"
      }
    );
  };

  const handleCancelClick = () => {
    setEditRowId(null);
  };

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      error,
      loading,
      editRowId,
      setEditRowId,
      defaultColumn,
      autoResetPage: true,
      addUserHandler,
      sendDataAPI,
      editFormData,
      handleEditFormChange,
      handleCancelClick,
      handleEditFormSubmit,
      handleDeleteClick,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      //hooks.visibleColumns.push((columns) => {
      //  return [
      //    {
      //      id: "selection",
      //      Header: ({ getToggleAllRowsSelectedProps }) => (
      //        <Checkbox {...getToggleAllRowsSelectedProps()} />
      //      ),
      //      Cell: ({ row }) => (
      //        <Checkbox {...row.getToggleRowSelectedProps()} />
      //      ),
      //    },
      //    ...columns,
      //  ];
      //});
    }
  );

  const { globalFilter, pageIndex, selectedRowIds, pageSize } = state;

  return (
    <>
      <div className="card py-2">
        <div className="card-body">
          <div className="table-responsive">
            <TableToolbar
              sendDataAPI={sendDataAPI}
              addUserHandler={addUserHandler}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
            />
            <div className="dataTables_wrapper">
              <form onSubmit={handleEditFormSubmit}>
                <Table {...getTableProps()} className="table dataTable display">
                  <thead>
                    {headerGroups.map((headerGroup) => {
                      const { key, ...headerGroupProps } =
                        headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key}>
                          {headerGroup.headers.map((column) => {
                            const { key, ...columnProps } =
                              column.getHeaderProps(
                                column.getSortByToggleProps()
                              );
                            return (
                              <th key={key} {...columnProps}>
                                {column.render("Header")}
                                <span className="ml-1">
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa fa-arrow-down" />
                                    ) : (
                                      <i className="fa fa-arrow-up" />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      if (editRowId === row.id) {
                        return (
                          <Editable
                            row={row}
                            editFormData={editFormData}
                            handleEditFormChange={handleEditFormChange}
                            handleCancelClick={handleCancelClick}
                          />
                        );
                      } else {
                        const { key, ...rowProps } = row.getRowProps();
                        return (
                          <tr key={key} {...rowProps}>
                            {row.cells.map((cell) => {
                              const { key, ...cellProps } = cell.getCellProps();
                              return (
                                <td key={key} {...cellProps}>
                                  {" "}
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                            {/* TO BE REVIEWED for future implementation
                             <td>
                              <div className="d-flex">
                                <button
                                  className="btn btn-secondary	 shadow btn-xs sharp me-2"
                                  onClick={(event) =>
                                    handleEditClick(event, row)
                                  }
                                >
                                  <i className="fas fa-pen"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => handleDeleteClick(row)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </td>
                            
                            
                            */}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              </form>
              <br />
              <div className="d-flex justify-content-between">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                  {""}
                </span>
                <span>
                  <select
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {[10, 25, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </span>

                <span>
                  <div className="text-center">
                    <button
                      className="btn btn-sm btn-outline-dark"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>

                    <button
                      className="btn btn-sm btn-outline-dark"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      Previous
                    </button>
                    <button
                      className="btn btn-sm btn-outline-dark"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      Next
                    </button>
                    <button
                      className="btn btn-sm btn-outline-dark"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                </span>

                <span className="table-index">
                  Go to page :{" "}
                  <input
                    type="number"
                    className="number-input ml-2"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
