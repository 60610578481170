export const BASENAME = ""; // don't add '/' at end off BASENAME
export const BASE_URL = "/app/dashboard/default";
export const BASE_TITLE = " | React Datta Able ";
export const SERVER_LIST = [
  { value: "http://localhost:5000",
    name: "Local Server"
   },
  { value: "https://evert.umana-analytics.com",
    name: "Staging: Evert"
  },
  { value: "https://dev.umana-analytics.com",
    name: "Staging: Dev"
  },
  { value: "https://platform.umana-analytics.com",
    name: "Production: Platform"
  },
  { value: "https://trust.umana-analytics.com",
    name: "Production: Trust"
  },
];

