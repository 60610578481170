import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import SettingsCard from "./SettingsCard";

const PaginateItems = (props) => {
  const { itemsPerPage, translations, refItems } = props;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(translations.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(translations.length / itemsPerPage));
  }, [translations, itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % translations.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-lg-6 py-4">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Translation Form</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  previousLabel="< previous"
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={1}
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>

        <SettingsCard
          currentItems={currentItems}
          translations={translations}
          refItems={refItems}
        />
      </div>
    </>
  );
};

export default PaginateItems;
