import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ListGroup } from "react-bootstrap";
import {
  SelectAllSurveys,
  SelectSurveyID,
} from "../../../../features/translations/translationsSlice";
import { setSurveyID } from "../../../../features/translations/translationsSlice";

import Select from "react-select";

const SurveyCard = () => {
  const surveyID = useSelector(SelectSurveyID);
  const surveys = useSelector(SelectAllSurveys);
  const dispatch = useDispatch();

  const handleSurveyChange = (event) => {
    dispatch(setSurveyID(parseInt(event.target.value)));
  };

  const options = surveys.map((survey) => ({
    value: survey.id,
    label: `${survey.title} (${survey.slug})`,
  }));

  const handleFindDetails = (item) => {
    const survey = surveys.find((survey) => survey.id === surveyID);

    if (!survey) {
      return "----";
    }

    switch (item) {
      case "title":
        return survey.title;
      case "slug":
        return survey.slug;
      default:
        return "----";
    }
  };
  return (
    <div className="col-xl-6 col-lg-6">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Survey Selection</h4>
        </div>
        <div className="card-body">
          <div className="basic-form">
            <div className="form-group py-2">
              <Select
                value={
                  options.find((option) => option.value === surveyID) || null
                }
                onChange={(selectedOption) =>
                  handleSurveyChange({
                    target: {
                      value: selectedOption ? selectedOption.value : -1,
                    },
                  })
                }
                options={[
                  { value: -1, label: "--- choose a survey ---" },
                  ...options,
                ]}
                //classNamePrefix="react-select" // Optional for styling
              />

              <div className="basic-list-group py-2">
                <ListGroup as="ul">
                  <ListGroup.Item as="li">
                    <dt>Survey Title: </dt> {handleFindDetails("title")}
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup className="py-2" as="ul">
                  <React.Fragment>
                    <ListGroup.Item as="li">
                      <dt>Survey slug:</dt> {handleFindDetails("slug")}
                    </ListGroup.Item>
                  </React.Fragment>
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyCard;
