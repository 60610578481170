import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice"; 
import { apiSlice } from "../api/apiSlice";
import translationsReducer from "../features/translations/translationsSlice";
import wizardReducer from "../features/wizard/wizardSlice";
import modalReducer from "../features/modal/modalSlice";
import projectReducer from "../features/projects/projectSlice";

const combinedReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  wizard: wizardReducer,
  modal: modalReducer,
  projects: projectReducer,
  translation: translationsReducer
 });

 const rootReducer = (state, action) => {
  if (action.type === 'RESET') { //We are calling this RESET, but call what you like!
     state = {};
  }
  return combinedReducer(state, action);
 };

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
});