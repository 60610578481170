import React from "react";

export const MenuFooter = () => {

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "auto",
      }}
    >
      
    </div>
  );
};
