import { apiSlice } from "../../api/apiSlice"
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedProject: null,
    projects: [],
    sameLocationFilter: false,
    sameLocationFilterValue: false,
    selectedFilters: ['Overall'],
    selectedEmployees: [],
    filters: [],
    projectData: {}
}

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProjects: (state, action) => {
            const { data } =
              action.payload;
            
              state.projects = data.map((item) => {
                return {
                  id: item.id,
                  name: item.name
                };
              });
            
            if (state.projects.length > 0) {
                state.selectedProject = state.projects.toReversed()[0].id
                state.selectedFilters = ['Overall']
                //below reformatting added for compatibility with the current frontend
                // TODO: refactor the filters part of the frontend to directly use arrays instead of objects.
                let filterData = {"Overall":"costrutto"}
                const selectedProject = data.find(({ id }) => id === state.selectedProject);
                selectedProject?.filters?.forEach(item => {
                    filterData[item.name] = item.column_name;
                });
                state.sameLocationFilter = selectedProject.same_location_filter
                state.filters = filterData
            } else {
                state.selectedProject = null
                state.selectedFilters = ['Overall']
                state.sameLocationFilter = false
                state.filters = []
            } 
            state.projectData = data;
          },
        updateProject: {
            reducer(state, action) {
                state.selectedProject= action.payload
                state.selectedFilters= ['Overall']
                let filterData = {"Overall":"costrutto"}
                const selectedProject = state.projectData.find(({ id }) => id === state.selectedProject);
                selectedProject?.filters?.forEach(item => {
                    filterData[item.name] = item.column_name;
                });
                state.sameLocationFilter = selectedProject.same_location_filter
                state.filters = filterData
            },
           
        },
        updateFilters: {
            reducer(state, action) {
                state.selectedFilters= action.payload
            },
            prepare(filter) {
                return {
                    payload: [filter]
                }
            }
        },
        toggleSameLocationFilter: {
            reducer(state, action) {
              state.sameLocationFilterValue = !state.sameLocationFilterValue;
            },
          },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
          apiSlice.endpoints.getProjects.matchFulfilled,
          (state, { payload }) => {
            const { data } = payload;
          
            state.projects = data.map((item) => {
                return {
                  id: item.id,
                  name: item.name
                };
              });
          
          if (state.projects.length > 0) {

              state.selectedProject = state.projects.toReversed()[0].id
              state.selectedFilters = ['Overall']              
              //below reformatting added for compatibility with the current frontend
              // TODO: refactor the filters part of the frontend to directly use arrays instead of objects.
              let filterData = {"Overall":"costrutto"}
              const selectedProject = data.find(({ id }) => id === state.selectedProject);
              selectedProject?.filters?.forEach(item => {
                  filterData[item.name] = item.column_name;
              });
              state.sameLocationFilter = selectedProject.same_location_filter
              state.filters = filterData
              state.selectedEmployees = selectedProject?.employees ? selectedProject.employees : []
          } else {
              state.selectedProject = null
              state.selectedFilters = ['Overall']
              state.sameLocationFilter = false
              state.filters = []
          } 
          state.projectData = data;
          }
        )
      },
})


export const { setProjects, updateProject, updateFilters, toggleSameLocationFilter } = projectSlice.actions

export const SelectCurrentProject = (state) => state.projects.selectedProject
export const SelectCurrentFilters = (state) => state.projects.selectedFilters
export const SelectCurrentEmployees = (state) => state.projects.selectedEmployees
export const selectAllProjects = (state) => state.projects.projects
export const SelectAllFilters = (state) => state.projects.filters
export const SelectSameLocationFilter = (state) => state.projects.sameLocationFilter;
export const SelectSameLocationFilterValue = (state) => state.projects.sameLocationFilterValue;

export default projectSlice.reducer